//===-----------------===
// Reusable functions
//===-----------------===

//===------- Toggle classes -------===//
function toggleClass(element, elementClass) {
  element.forEach((index) => {
    if (index) {
      if (index.classList.contains(elementClass)) {
        index.classList.remove(elementClass);
      } else {
        index.classList.add(elementClass);
      }
    }
  });
}

//===-----------------===
// Accordions
//===-----------------===
if (document.querySelector('.accordion')) {
  const accordionItems = document.querySelectorAll('.accordion__item');
  accordionItems.forEach((item) => {
    const accordionBtn = item.querySelector('.accordion__btn');
    accordionBtn.addEventListener('click', function (e) {
      const ariaExpanded = e.target.getAttribute('aria-expanded');
      if (ariaExpanded == 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        const targetContent = e.target.getAttribute('data-toggle-id');
        e.target.classList.add('js-open');
        document.querySelector('#' + targetContent).classList.add('js-open');
      } else {
        const targetContentID = e.target.getAttribute('data-toggle-id');
        const targetContent = document.getElementById(targetContentID);
        e.target.setAttribute('aria-expanded', 'false');
        targetContent.classList.add('js-close');
        setTimeout(() => {
          targetContent.classList.remove('js-open');
          e.target.classList.remove('js-open');
          targetContent.classList.remove('js-close');
        }, 300);
      }
    });
  });
}

//===-----------------===
// Tabs
//===-----------------===
if (document.querySelector('.tabs')) {
  // Getting all the tab buttons
  const tabButtons = document.querySelectorAll('.tab__btn');
  // Looping through them all for a click event
  tabButtons.forEach((button) => {
    button.addEventListener('keyup', function (e) {
      if (e.keyCode == 37 || e.keyCode == 39) {
        if (e.target.nextElementSibling.nextElementSibling) {
          e.target.nextElementSibling.nextElementSibling.focus();
        } else {
          e.target.previousElementSibling.previousElementSibling.focus();
        }
      }
    });

    button.addEventListener('click', function (e) {
      // Changing the tabs status based on which tab is opened
      const elAriaTitle = e.target.getAttribute('data-tab-id');
      document.getElementById('tab-status').innerHTML =
        elAriaTitle + ' tab opened. Navigate to the content below.';

      //Checking to see if the button clicked is already active
      if (e.target.classList.contains('active')) {
        //If so stop everything
        e.stopImmediatePropagation();
      } else {
        //Gathering the clicked buttons data attribute for the data-tab-id
        let tabContent = document.querySelector(
          '#' + e.target.getAttribute('data-tab-id')
        );

        //removing all actives classes from all buttons and their corresponding tab content
        tabButtons.forEach((tabButton) => {
          let tabContent = document.querySelector(
            '#' + tabButton.getAttribute('data-tab-id')
          );
          tabButton.setAttribute('aria-selected', 'false');
          tabButton.classList.remove('active');
          tabContent.classList.remove('active');
          tabContent.setAttribute('aria-expanded', 'false');
        });

        //adding a class to the clicked button and its tab content
        e.target.classList.add('active');
        e.target.setAttribute('aria-selected', 'true');
        tabContent.classList.add('active');
        tabContent.setAttribute('aria-expanded', 'true');
      }
    });
  });
}

//===-----------------===
// Navigation
//===-----------------===
if (document.querySelector('.site-nav')) {
  let navBtn = document.querySelector('.site-nav__btn'),
    siteNav = document.querySelector('.site-nav');

  navBtn.addEventListener('click', function () {
    toggleClass(
      [siteNav, navBtn, document.querySelector('body')],
      'js-menu-open'
    );
    if (!siteNav.classList.contains('js-menu-open')) {
      siteNav.classList.add('js-menu-close');
      setTimeout(() => {
        siteNav.classList.remove('js-menu-close');
      }, 300);
    }
    if (!navBtn.classList.contains('js-menu-open')) {
      navBtn.classList.add('js-menu-close');
      setTimeout(() => {
        navBtn.classList.remove('js-menu-close');
      }, 300);
    }
    if (navBtn.getAttribute('aria-expanded') == 'false') {
      navBtn.setAttribute('aria-expanded', 'true');
    } else {
      navBtn.setAttribute('aria-expanded', 'false');
    }
  });

  navBtn.addEventListener('keyup', function (evt) {
    if (evt.code == 'Escape') {
      toggleClass(
        [siteNav, navBtn, document.querySelector('body')],
        'js-menu-open'
      );
    }
  });

  siteNav.querySelectorAll('a').forEach((link) => {
    link.addEventListener('keyup', function (evt) {
      if (evt.code == 'Escape') {
        toggleClass(
          [siteNav, navBtn, document.querySelector('body')],
          'js-menu-open'
        );
      }
    });
  });

  //===-----------------===
  // Overlay - Menu close
  //===-----------------===
  if (document.querySelector('.overlay')) {
    document.querySelector('.overlay').addEventListener('click', function () {
      toggleClass(
        [siteNav, document.querySelector('body'), navBtn],
        'js-menu-open'
      );
      if (navBtn.getAttribute('aria-expanded') == 'false') {
        navBtn.setAttribute('aria-expanded', 'true');
      } else {
        navBtn.setAttribute('aria-expanded', 'false');
      }
      if (!navBtn.classList.contains('js-menu-open')) {
        navBtn.classList.add('js-menu-close');
        setTimeout(() => {
          navBtn.classList.remove('js-menu-close');
        }, 500);
      }
    });
  }
}

if (document.querySelector('.form-group--radio')) {
  const radioIcons = document.querySelectorAll('input[type="radio"]');

  radioIcons.forEach((radio) => {
    radio.addEventListener('click', function () {
      const radioLabels = document.querySelectorAll('.form-group--radio label');
      radioLabels.forEach((label) => {
        if (label.classList.contains('js-radio-checked')) {
          label.classList.remove('js-radio-checked');
        }
      });
      if (
        radio.checked &&
        !radio.parentElement.classList.contains('js-radio-checked')
      ) {
        radio.parentElement.classList.add('js-radio-checked');
      } else {
        radio.parentElement.classList.remove('js-radio-checked');
      }
    });
  });
}
